import React from "react"
import { NavLink, Link } from 'react-router-dom'
import "./css/header.css"

const Header = () => {
  const logoPNG = "/images/Artboard 3.png";

  return (
    <header className="header">
      <style>
        @import url('https://fonts.googleapis.com/css2?family=Birthstone&family=Fraunces:ital,opsz,wght,SOFT,WONK@0,9..144,100..900,0..100,0..1;1,9..144,100..900,0..100,0..1&family=Mako&display=swap');
      </style>
      <div className="header-btm-line">
        <Link to={`/`}>
          <img className="header-logo" src={logoPNG} alt="" />
        </Link>

      </div>
      <div className="header-navigation">
        <NavLink className="header-nav-option" to="/"> catalogue </NavLink>
        <NavLink className="header-nav-option" to="/about"> about </NavLink>
      </div>

    </header>
  )
}

export default Header
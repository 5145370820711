import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom"

import Header from "./components/header";
import Catalogue from "./pages/catalogue"
import Home from "./pages/home"
import Product from './pages/product';
import './App.css';


function App() {
  const telegramLogo = "/images/tele-logo.png";
  const instagramLogo = "/images/Instagram_Glyph_Gradient.png";
  return (
    <div className="App">
      <Router>
        <Header />
        <div>
          <Routes>
            <Route path="/" element={<Catalogue />} />
            <Route path="/about" element={<Home />} />
            <Route path="/catalogue/:productId" element={<Product />} />
          </Routes>
        </div>
      </Router>
      <a href="https://t.me/twentiessg" target="_blank" rel="noopener noreferrer">
        <img className="telegram" src={telegramLogo} alt="Contact us on Telegram" />
      </a>
      <a href="https://www.instagram.com/2.wenties/" target="_blank" rel="noopener noreferrer">
        <img className="instagram" src={instagramLogo} alt="Contact us on Instagram" />
      </a>
    </div>
  );
}

export default App;

import React from 'react';
import { Link } from 'react-router-dom';
import "./catalogueComponent.css";

const ProductCard = ({ product }) => {
    const imagePath = `/product-images/${product.id}/thumbnail.jpg`;

    return (
        <div className="product-card">
            <Link to={`/catalogue/${product.id}`} state={{ product }}>
                <img src={imagePath} alt={product.title} className="product-image" />
            </Link>
            <div className='product-details'>
                <h2 className="product-title">{product.title}</h2>
                <p className="product-price">{product.price}</p>
            </div>
        </div>
    );
};

export default ProductCard;

import React from 'react'

import ProductList from '../components/catalogue/productList';
import productData from '../data/productData.json';
import "./css/catalogue.css"

// import productImage1 from "../data/images/ao babytee 6.jpg"
// import productImage2 from "../data/images/ao day nhun 2.jpg"
// import productImage3 from "../data/images/ao thun dong phuc 2.jpg"
// import productImage4 from "../data/images/quan jean zip 5.jpg"
// import productImage5 from "../data/images/Set corset ren 2.jpg"

function catalogue() {
    return (
        <div className='catalogue-main-con'>
            <div className='catalogue-left-pad'></div>
            <div className='catalogue-middle-pad'>
                <ProductList products={productData} />
            </div>
            <div className='catalogue-right-pad'></div>
        </div>
        
        
    )
}

export default catalogue